import lazysizes from 'lazysizes';

Object.assign(lazysizes.cfg, {
  init: false,
});

const module = {
  needsPicturefill() {
    const img = document.createElement('img');

    return !(
      ('srcset' in img)
      && ('sizes' in img)
      && ('HTMLPictureElement' in window)
    );
  },

  init() {
    if (this.needsPicturefill()) {
      import(/* webpackChunkName: "picturefill" */ 'picturefill')
        .then(() => {
          lazysizes.init();
        });
    } else {
      lazysizes.init();
    }
  },
};

export default module;
