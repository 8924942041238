// eslint-disable-next-line no-unused-vars
import FontFaceObserver from 'fontfaceobserver';

(() => {
  const fonts = [
    new FontFaceObserver('Catamaran', { weight: 100 }),
    new FontFaceObserver('Catamaran', { weight: 200 }),
    new FontFaceObserver('Catamaran', { weight: 300 }),
    new FontFaceObserver('Catamaran', { weight: 400 }),
    new FontFaceObserver('Catamaran', { weight: 500 }),
    new FontFaceObserver('Catamaran', { weight: 600 }),
    new FontFaceObserver('Catamaran', { weight: 700 }),
    new FontFaceObserver('Catamaran', { weight: 800 }),
    new FontFaceObserver('Catamaran', { weight: 900 }),
  ].map(font => font.load());

  if (!fonts.length) {
    return;
  }

  Promise.all(fonts).then(() => {
    document.documentElement.classList.add('fonts-loaded');
  });
})();
